import React, { useState, useEffect } from "react";
import './motorcycles.css';
import motorcyclesData from '../../data/motorcycles.json';

function Motorcycles() {
  const [motorcycles, setMotorcycles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setMotorcycles(motorcyclesData);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 770);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [motorcyclesData]);

  const nextMotorcycle = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % motorcycles.length);
  };

  const prevMotorcycle = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + motorcycles.length) % motorcycles.length);
  };

  const displayedMotorcycles = isMobile
    ? [motorcycles[currentIndex]] // Показываем один мотоцикл на мобильных устройствах
    : [
        motorcycles[currentIndex],
        motorcycles[(currentIndex + 1) % motorcycles.length],
        motorcycles[(currentIndex + 2) % motorcycles.length]
      ];

  if (motorcycles.length === 0) {
    return <div>Нет доступных мотоциклов</div>;
  }

  return (
    <div className="motorcycles">
      <h3 className="motorcycles-header">Наша техника</h3>
      <div className="motorcycles-container">
        <button onClick={prevMotorcycle} className="arrow-button">❮</button>
        <div className="motorcycle-cards">
          {displayedMotorcycles.map(motorcycle => (
            <div key={motorcycle.id} className="motorcycle-card">
              <img src={motorcycle.image} alt={motorcycle.title} className="motorcycle-image" />
              <h3 className="motorcycle-title">{motorcycle.title}</h3>
              <p className="motorcycle-description">{motorcycle.description}</p>
            </div>
          ))}
        </div>
        <button onClick={nextMotorcycle} className="arrow-button">❯</button>
      </div>
    </div>
  );
}

export default Motorcycles;
