import React from "react";
import './promo.css';

function Promo() {
    return (
        <div className="promo">
            <div className="promo-text">
                <h1>Прокат эндуро мотоциклов и ПитБайков в Екатеринбурге</h1>
                <p>
                    Свободное катание<br />
                    Мото туры<br />
                    Групповые катания с инструктором
                </p>
                <div>
                    {/* Здесь можно добавить ссылки или кнопки */}
                </div>
            </div>
        </div>
    );
}

export default Promo;
