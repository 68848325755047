import React, { useState, useEffect } from "react";
import pricesData from '../../data/prices.json';
import './pricesAndInfo.css';

function PricesAndInfo() {
  const [prices, setPrices] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      setPrices(pricesData);
      setLoading(false);
    } catch (error) {
      setError('Ошибка получения данных о ценах');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (prices) {
    return (
      <div className="prices-and-info" id="price">
        <div className="prices">
          <h2 className="price-header">Цены :</h2>
          <div className="price-item">
            <h3>Эндуро :</h3>
            <p>{prices.enduro.hourly["1_hour"]} руб. за 1 час</p>
            <p>{prices.enduro.hourly["2_hours"]} руб. за 2 часа (следующие часы по {prices.enduro.hourly.additional_hour} руб.)</p>
            <p>Свыше 6 часов {prices.enduro.hourly.full_day} руб/1час</p>
          </div>
          <div className="price-item">
            <h3>Питбайки :</h3>
            <p>{prices.pitbikes.hourly["1_hour"]} руб. за 1 час</p>
            <p>{prices.pitbikes.hourly["2_hours"]} руб. за 2 часа (следующие часы по {prices.pitbikes.hourly.additional_hour} руб.)</p>
            <p> Свыше 6 часов{prices.pitbikes.hourly.full_day} руб/1час</p>
          </div>
          <div className="price-item">
            <h3>Прокат - аренда мотоциклов без инструктора:</h3>
            <p>1500р 1/ч</p>
            <p>Свыше 3/ч 1200р 1/ч</p>
            <p>Свыше 6/ч проката 1т.р/1ч</p>
          </div>
        </div>
        <div className="safety-info">
          <h2>Мы заботимся о вашей безопасности:</h2>
          <p>📌Полный комплект защитной экипировки и инструктаж по технике безопасности гарантируют вашу защиту!</p>
          <p>⚠️Внимание: Участие в мототурах разрешено лицам старше 18 лет. В случае если вы моложе 18 лет, необходимо наличие родителей или законных опекунов во время мотопоездки.</p>
          <p>🚦 Категория "А" не требуется для участия в наших мототурах, так что даже если у вас нет опыта вождения мотоцикла, не беспокойтесь, мы предоставим вам всю необходимую инструкцию и поддержку!</p>
        </div>
      </div>
    );
  }

  return <div>Нет данных для отображения</div>;
}

export default PricesAndInfo;




