import React, { useState } from "react";
import logo from '../../img/logo.png';
import telegramIcon from '../../icons/telegram.png';
import phoneIcon from '../../icons/phone.png';
import wappIcon from '../../icons/whatsApp.png';
import './header.css';

function scrollToElement(id) {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    }
}

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <header className="header">
            <div>
                <a href="#" className="header-logo">
                    <img src={logo} alt="logo" />
                </a>
            </div>
            <button className="burger-menu" onClick={toggleMenu}>
                ☰
            </button>
            <div className={`popup-menu ${isMenuOpen ? "open" : ""}`}>
                <button className="close-menu" onClick={closeMenu}>
                    ✖
                </button>
                <ul className="header-link-list">
                    <li><a href="#route" onClick={() => { scrollToElement("route"); closeMenu(); }}>Маршруты</a></li>
                    <li><a href="#price" onClick={() => { scrollToElement("price"); closeMenu(); }}>Стоимость</a></li>
                </ul>
                <div className="header-contact">
                    <a href="https://t.me/motoprokat_ham">
                        <img src={telegramIcon} alt="telegram" className="contact-icon" />
                    </a>
                    <a href="https://wa.me/79920083299">
                        <img src={wappIcon} alt="whatsapp" className="contact-icon" />
                    </a>
                    <a href="tel:+79920083299">
                        <img src={phoneIcon} alt="phone" className="contact-icon" />
                    </a>
                </div>
            </div>
            <div className="desktop-menu">
                <div className="header-contact">
                    <a href="https://t.me/motoprokat_ham">
                        <img src={telegramIcon} alt="telegram" className="contact-icon" />
                    </a>
                    <a href="https://wa.me/79920083299">
                        <img src={wappIcon} alt="whatsapp" className="contact-icon" />
                    </a>
                    <a href="tel:+79920083299">
                        <img src={phoneIcon} alt="phone" className="contact-icon" />
                    </a>
                </div>
                <ul className="header-link-list">
                    <li><a href="#route" onClick={() => scrollToElement("route")}>Маршруты</a></li>
                    <li><a href="#price" onClick={() => scrollToElement("price")}>Стоимость</a></li>
                </ul>
            </div>
        </header>
    );
}

export default Header;